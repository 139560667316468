.show-top { 
    width: 350px;
    position: fixed; 
    top: 85px;
    right: 20px; 
    z-index: 9999; 
}

.show-top .alert-dismissible {
    padding-right: 1rem !important;
    margin-bottom: 3px;
    padding-top: 0.4rem;
}

.show-top .alert {
    --bs-alert-border: 2px solid var(--bs-alert-border-color)
}