/* reset.css */
ol,
ul {
  list-style: none;
}

b,
strong {
  font-weight: bold;
}

i,
dfn {
  font-style: italic;
}

img {
  max-width: 100%;
}


/* ************************************* GLOBAL THEME STYLES ************************************* */

html,
body {
  height: 100%;
}

/* hiding placeholder text on focus in webkit, thanks to http://www.kolodvor.net/2012/03/23/webkit-placeholder-attribute-behavior */
input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  color: transparent;
}


/* clearfix - nicolasgallagher.com/micro-clearfix-hack/ */
.clearfix {
  zoom: 1;
}

.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-narbhavi {
  color: #669900;
}

.modal-header {
  background-color: #669900;
}

.modal-title {
  color: #ffffff;
}

.modal-footer {
  border-top: 3px solid #dfe0e1;
  background-color: #ecedee;
}

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 6px;
  border-radius: 60px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 6px;
  border-radius: 6px;
  background: #6d6d6d;
}


div.show-top {
  grid-area: alert;
}

.main {
  grid-area: main;
}

header {
  grid-area: header;
}

.border-line {
  grid-area: separator
}

.hline {
  grid-area: t-separator
}

footer {
  grid-area: footer;
}

aside {
  grid-area: aside;
}

@media only screen and (max-width: 600px) {
  .grid-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      "alert"
      "header"
      "t-separator"
      "main"
      "aside"
      "separator"
      "footer"
  }

  aside {
    width: 100%;
  }

  .logo {
    min-height: 100%;
    margin-left: -8px;
  }

  #brandlogo {
    width: 80%;
  }

  #sitesearch {
    position: relative;
    float: right;
    right: 8px;
    top: -26px;
    bottom: 0;
    height: 14px;
    margin: auto;
    font-size: 14px;
    cursor: pointer;
    color: #fff;
    z-index: 999;
  }
}


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 600px) and (max-width: 768px) {
  .grid-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      "alert"
      "header"
      "t-separator"
      "main"
      "aside"
      "separator"
      "footer"
  }
  .logo {
    min-height: 100%;
    margin-left: -8px;
  }

  aside {
    width: 100%;
  }

  #sitesearch {
    position: relative;
    float: right;
    right: 8px;
    top: -26px;
    bottom: 0;
    height: 14px;
    margin: auto;
    font-size: 14px;
    cursor: pointer;
    color: #fff;
    z-index: 999;
  }

  #brandlogo {
    width: 80%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .grid-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      "alert"
      "header"
      "t-separator"
      "main"
      "aside"
      "separator"
      "footer"
  }

  .logo {
    min-height: 100%;
    margin-left: -8px;
  }

  aside {
    width: 100%;
  }

  #sitesearch {
    position: relative;
    float: right;
    right: 8px;
    top: -26px;
    bottom: 0;
    height: 14px;
    margin: auto;
    font-size: 14px;
    cursor: pointer;
    color: #fff;
    z-index: 999;
  }

  #brandlogo {
    width: 80%;
  }
}

.hline {
  background-color: #88bb48;
  height: 3px;
}

@media only screen and (min-width:992px) {
  .grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 0px 80px 3px auto 8px auto;
    grid-template-areas:
      "alert alert alert alert"
      "header header header header"
      "t-separator t-separator t-separator t-separator"
      "main main main aside"
      "separator separator separator separator"
      "footer footer footer footer"
  }

  .offcanvas-body {
    padding: 0;
  }

  #brandlogo {
    width: 55%;
    height: auto;
    padding-left: 10px; 
  }

  #sitesearch {
    position: relative;
    right: -158px;
    top: -28px;
    bottom: 0;
    height: 14px;
    margin: auto;
    font-size: 14px;
    cursor: pointer;
    color: #fff;
    z-index: 999;
  }

  #sitesearchinput {
    position: relative;
    min-width: 100px;
    max-width: 200px;
    background-color: #669900;
    color: #ffffff;
    border-color: #88bb48;
  }

  form.site-search {
    position: relative;
    height: 45px;
    padding: 5px 10px;
  }

  nav {
    flex: 1; /* Let the column grow to take available space */
    display: flex;
    justify-content: flex-end; /* Align contents to the end (right) of the column */
  }

  .dropdown-menu {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .navbar .navbar-nav .nav-item.dropdown:hover .dropdown-menu {
    margin-top: 0;
  }

  .dropdown-menu[data-bs-popper] {
    margin-top: -1px;
    margin-right: -1px;
  }

  a.nav-link {
    color: white !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .sf-menu li {
    position: relative;
  }

  .sf-menu>li {
    border-right: 1px solid #4A5D23;
  }

  .sf-menu>li.last>a {
    border-right: 0px;
    height: 100%;
  }

  .sf-menu>li>a {
    height: 35px;
    display: block;
    border-right: 1px solid #222;
    font: normal 0.8rem/1.3rem 'Arial';
    color: #ffffff;
    text-transform: uppercase;
  }

  .sf-menu>li.active,
  .sf-menu>li:hover {
    border-color: #aace5f;
  }

  .sf-menu>li.active>a,
  .sf-menu>li:hover>a {
    border-color: #88bb48;
    background: #aace5f;
    /* Old browsers */
    background: -webkit-linear-gradient(top, #aace5f 0%, #88bb48 100%);
    /* Chrome10+,Safari5.1+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #aace5f), color-stop(100%, #88bb48));
    /* Chrome,Safari4+ */
    background: -moz-linear-gradient(top, #aace5f 0%, #88bb48 100%);
    /* FF3.6+ */
    background: -ms-linear-gradient(top, #aace5f 0%, #88bb48 100%);
    /* IE10+ */
    background: -o-linear-gradient(top, #aace5f 0%, #88bb48 100%);
    /* Opera 11.10+ */
    background: linear-gradient(to bottom, #aace5f 0%, #88bb48 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#aace5f', endColorstr='#88bb48', GradientType=0);
    /* IE6-8 */
  }

  .gradient {
    background: #424242;
    /* Old browsers */
    background: -webkit-linear-gradient(top, #545D23 0%, #17312F 100%);
    /* Chrome10+,Safari5.1+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #424242), color-stop(100%, #17312F));
    /* Chrome,Safari4+ */
    background: -moz-linear-gradient(top, #545D23 0%, #17312F 100%);
    /* FF3.6+ */
    background: -ms-linear-gradient(top, #545D23 0%, #17312F 100%);
    /* IE10+ */
    background: -o-linear-gradient(top, #545D23 0%, #17312F 100%);
    /* Opera 11.10+ */
    background: linear-gradient(to bottom, #545D23 0%, #17312F 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#545D23', endColorstr='#17312F', GradientType=0);
    /* IE6-8 */
  }
}