#plantgrid th:hover {
    background-color: #6d6d6d;
    color: #fff;
    cursor: pointer;
}

#searchclear {
    position: absolute;
    right: 12px;
    top: -6px;
    bottom: 0;
    height: 14px;
    margin: auto;
    font-size: 14px;
    cursor: pointer;
    color: #ccc;
    z-index: 999;
}