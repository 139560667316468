.carousel {
  margin: 0 auto; /* Set the left and right margin to auto */
}

.c1 {
  background-image: url('./carousel1.jpg');
}

.grad {
  height: 200px;
  width: 100%;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(70, 69, 69, 0.557) 14%,rgba(46,56,40,1) 100%);
}

.carousel-caption > h2, .carousel-caption > p {
  text-align: left;
  position: relative;
  bottom: 2.25rem;  
  margin-left: 5%;
  margin-right: 5%;  
}

.carousel-caption > h2 {
  font-weight: bold;
}

.carousel-control-next, .carousel-control-prev {
  width: fit-content;
  padding-left: calc(var(--bs-gutter-x) * .7);
  padding-right: calc(var(--bs-gutter-x) * .7);  
}


main .expanding-column {
  flex-grow: 1; /* Allow the column to expand */
  background-color: #ccc;
}

.carousel-item img {
  width: 100%;
  height: 100%;
  min-height: 250px;
  transform: scale(1.15, 1.15);
  max-height: calc(100vh - 165px);
  object-fit: cover;
  opacity: 0.8;
}

.carousel-item img:hover{
  backface-visibility: hidden;
  transform: scale(1.15, 1.15);
  opacity: 1;
  animation: zoom-in-zoom-out 5s ease 1;
}

aside {
  display: flex;
  flex-direction: column;
  background-color: azure;
}

aside > div {
  flex-grow: 1;
  background-color: white;
  border: 1px solid yellowgreen;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1.25, 1.25);
  }  
  100% {
    transform: scale(1.55, 1.55);
  }
}