.bi {
  vertical-align: -.125em;
  fill: currentColor;
}

.top {
  height: 8px;
  background-color: #4A5D23
}

.usericon {
  color: #ffffff
}

header .search {
  background-color: #669900;
}

header .menu-items {
  width: max-content;
  height: 43px !important;
}

header .border-line {
  background-color: #88bb48;
  height: 8px;
}

.bg-nbv {
  position: relative;
  background-color: #669900;
  width: 100%;
}

.bg-nbv::before {
  content: "";
  position: absolute;
  background-image: url('./leaves_bg.svg');
  background-repeat: repeat-x;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.3;
}

#sitesearchinput::placeholder {
  color: #c2c2c2;
}

#sitesearchinput:focus {
  border-color: #B4C424;
  /* set the border color to blue */
  box-shadow: 0 0 0 0.2rem rgba(181, 230, 29, 0.25);
}

main .expanding-column {
  flex-grow: 1;
  /* Allow the column to expand */
  background-color: #ccc;
}

/* Pulse Shrink */
@-webkit-keyframes logo-hvr-shrink {
  to {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes logo-hvr-shrink {
  to {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}


.logo-hvr-shrink {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
}

.logo-hvr-shrink:hover,
.logo-hvr-shrink:focus,
.logo-hvr-shrink:active {
  -webkit-animation-name: logo-hvr-shrink;
  animation-name: logo-hvr-shrink;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

#navigation {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}


/* LINKS */
header>a {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;  
}

header>a:hover,
header>a:focus {
  color: #88bb48;
}

.hidden-lg {
  width: 100%;
  padding: 5px;
  background: #eee;
  border: none;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

header {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.header__search-menu {
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-end;
  height: 100%;
}