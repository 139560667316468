.border-line {
  position: relative;
  background-color: #88bb48;
  height: 8px;
}

footer {
  display: flex;  
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: 20px;
  background-color: #4A5D23;
  background-image: url('./leaves_bg.svg');
  color: #fff;
  width: 100%;
  padding: 1rem;
}

.footer--copyright {
  width: 100%
}

.footer--section {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
}

.footer--terms {
  width: 25%;
  min-width: fit-content;
  padding-right: 20px;
}

.footer--phone {
  display: flex;
  min-width: fit-content;
  flex-direction: row;
  flex-wrap: wrap;
  width: 25%;
}

.footer--phone>div {
  padding-left: 5px;
  padding-right: 5px;
}

.footer--address {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: auto;
  width: 25%;
}

.footer--address>div {
  padding-left: 5px;
  padding-right: 5px;
}

.footer--email {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 25%;
  min-width: fit-content;
  padding-right: 20px;
}

.footer--email>div {
  padding-left: 5px;
  padding-right: 5px;
}